import request from '../config/axios';
import store from '../store';

export default class HistoryDataService {
    historyDataTable = (params) => {
        const siteId = store.getters.currentSelectedSite.id;
        let url = '/v1/app/' + siteId + '/platformRecordV5/0/' + params.dataType + '/' + params.startTs + '/' + params.endTs + '?page=' + params.current + '&pageSize=' + params.size;
        return request({
            url: url,
            method: 'get',
        });
    };
}