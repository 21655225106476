<template>
    <div class='card main-card layout-invoice-page'>
        <div class='flex'>
            <Button type='button' label='打印' icon='pi pi-print' @click='printClick'
                    style='display:block;margin-bottom: 20px; margin-left: 6px;'
                    v-tooltip="'建议在明亮主题模式下，使用Chrome浏览器进行打印'"
                    class='p-button-outlined invoice-button align-content-end'></Button>
        </div>
        <div style='width:90%' class='align-self-center' id='invoice-content'>
            <div class='flex justify-content-center'>
                <h5>{{ siteType }}排放连续监测小时平均值日报表</h5>
            </div>
            <DataTable :value='tableData' showGridlines selectionMode="single" responsiveLayout='scroll' :loading='loading'>
                <template #header>
                    <div>
                        <div>
                            <span>企业名称：</span>
                            <span>{{ $store.getters.currentSelectedEnterprise.enterpriseName }}</span>
                        </div>
                        <div class='table-header'>
                            <div>
                                <span>排口名称：</span>
                                <span>{{ $store.getters.routerRoot }}</span>
                            </div>
                            <div>
                                <span>监测日期：</span>
                                <Calendar v-model='dateValue' @date-select='dateSelect' date-format='yy年mm月dd日'>
                                </Calendar>
                            </div>
                        </div>
                    </div>
                </template>

                <ColumnGroup type='header'>
                    <Row>
                        <Column rowspan='2' header='时间' />
                        <Column v-for='col of tableColumns' :key='col.header' :header='col.header' />
                    </Row>
                    <Row>
                        <Column v-for='col of tableColumns' :key='col.field'
                                :header="col.unit === '' || col.unit === null ? '/' : col.unit " class='xl:text-sm' />
                    </Row>
                </ColumnGroup>
                <Column field='dateTime' class='xl:text-sm'></Column>
                <Column v-for='col of tableColumns' :key='col.field' :field="col.field + '-avg'" class='xl:text-sm' />

                <template #footer>
                    <div class='flex flex-row justify-content-around'>
                        <div>
                            报告人：
                        </div>
                        <div>
                            报告日期：
                        </div>
                    </div>
                </template>
            </DataTable>
        </div>

    </div>
</template>
<script>
import HistoryDataService from '../service/HistoryDataService';
import dayjs from 'dayjs';

export default {
    data() {
        return {
            loading: true,
            siteType: '废水',
            tableData: [],
            tableSummary: [],
            tableColumns: [],
            dataQueryParams: null,
            dateValue: dayjs().toDate(),
        };
    },
    historyDataService: null,
    created() {
        this.historyDataService = new HistoryDataService();
    },
    mounted() {
        const siteTypeId = this.$store.getters.currentSelectedSite.siteTypeId;
        if (siteTypeId === 'ea64fbb56d13a5fe0fa181693eefd227') {
            this.siteType = '废气';
        }
        let yesterday = dayjs().add(-1, 'day');
        this.dataQueryParams = {
            'dataType': '3',
            'startTs': yesterday.startOf('day').valueOf(),
            'endTs': yesterday.valueOf(),
            'current': 1,
            'size': 35,
        };
        this.loadHistoryDataTableData();
    },
    methods: {
        printClick() {
            window.print();
        },
        dateSelect(value) {
            this.dateValue = value;
            let selectedDay = dayjs(this.dateValue.getTime());
            this.dataQueryParams.startTs = selectedDay.startOf('day').valueOf();
            this.dataQueryParams.endTs = selectedDay.endOf('day').valueOf();
            // console.log(this.dataQueryParams);
            this.loadHistoryDataTableData();
        },
        loadHistoryDataTableData() {
            this.loading = true;
            this.historyDataService.historyDataTable(this.dataQueryParams).then(res => {
                if (res.data.success) {
                    this.tableData = res.data.data.page.data;

                    this.tableData.forEach(d => {
                        d.dateTime = dayjs(d.ts).format('HH 时');
                    });

                    let pollutionList = res.data.data.pollutionList;
                    this.tableColumns = [];
                    pollutionList.forEach(p => {
                        this.tableColumns.push({
                            'field': p.polId + '-' + p.polCode,
                            'header': p.polName !== null ? p.polName : p.polCode + '(未知编码)',
                            'unit': p.unit,
                            'couUnit': p.emissionsUnit === null ? '' : p.emissionsUnit,
                        });
                    });

                    // 平均值
                    let rowAvg = { 'dateTime': '平均值' };
                    let rowMax = { 'dateTime': '最大值' };
                    let rowMin = { 'dateTime': '最小值' };
                    let rowCount = { 'dateTime': '样本数' };
                    let rowSum = { 'dateTime': '日排放量' };
                    let rowSumUnit = { 'dateTime': '排放量单位' };
                    this.tableColumns.forEach(col => {
                        const field = col.field + '-avg';
                        const couField = col.field + '-cou';

                        let sum = 0;
                        let count = 0;
                        let max = -100000000;
                        let min = 1000000000;
                        let cou = 0;
                        this.tableData.forEach(d => {
                            if (d[field] != null && d[couField] != null) {
                                sum += parseFloat(d[field]);
                                count++;

                                if (parseFloat(d[field]) > max) {
                                    max = parseFloat(d[field]);
                                }

                                if (parseFloat(d[field]) < min) {
                                    min = parseFloat(d[field]);
                                }
                                cou += parseFloat(d[couField]);
                            }
                        });

                        rowAvg[field] = (sum / count).toFixed(4);
                        rowMax[field] = max.toFixed(4);
                        rowMin[field] = min.toFixed(4);
                        rowCount[field] = count;
                        if (field.startsWith('2-')){
                            cou = 0;
                        }
                        rowSum[field] = cou.toFixed(4);
                        rowSumUnit[field] = col.couUnit;
                    });
                    this.tableData.push({ 'dateTime': '/' });
                    this.tableData.push(rowAvg);
                    this.tableData.push(rowMax);
                    this.tableData.push(rowMin);
                    this.tableData.push(rowCount);
                    this.tableData.push(rowSum);
                    this.tableData.push(rowSumUnit);

                    // console.log(this.tableData);
                    // this.loading = false;
                } else {
                    this.$toast.add({ severity: 'error', summary: '错误提示', detail: '获取数据失败！', life: 3000 });
                }
                this.loading = false;
            });
        },
    },
};

</script>
<style>
.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-card {
    display: flex;
    flex-direction: column;
}
</style>